import React from "react"
import Layout from "../components/Layout"
import T from "../images/T.png"
import TKidBody from "../images/TKid-Body.png"
import Thank from "../images/Thank-You-Pop-Up.png"

const Thankyou = () => {
  return (
    <Layout>
      <section class="text-gray-600 pt-[5%] body-font">
        <div class="container relative mx-auto flex px-5 items-center justify-center flex-col">
          <img
            class="w-[38%] pt-[5%] object-cover object-center rounded relative"
            alt="hero"
            src={TKidBody}
          />

          <img
            class="w-[15%] object-cover object-center rounded absolute top-[20%] animate-wiggle"
            alt="hero"
            src={T}
          />
          <img
            class="w-[25%] object-cover object-center rounded absolute top-[-20%] animate__animated animate__rubberBand"
            alt="hero"
            src={Thank}
          />
        </div>
      </section>
    </Layout>
  )
}

export default Thankyou
